<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-menu
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <!-- language -->
    <!-- 
            bottom
      center
      origin="top center"
     -->
    <!-- <v-menu
      dense
      offset-y
      rounded="0"
      elevation="0"
      transition="scale-transition"
      origin="center center"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-web</v-icon>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <app-bar-item v-for="(n, i) in ['en', 'ar']" :key="`item-${i}`">
            <v-list-item-title v-text="n" @click="changeLang(n)" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu> -->

    <v-spacer />

    <!-- <v-text-field
      :label="$t('search')"
      color="secondary"
      hide-details
      style="max-width: 250px;"
    >
      <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:append-outer>
        <v-btn class="mt-n2" elevation="1" fab small>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field> -->

    <div class="mx-3" />

    <v-btn class="ml-2" min-width="0" text to="/">
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

    <!-- notification -->
    <!-- <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-badge color="red" overlap bordered>
            <template v-slot:badge>
              <span>5</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <app-bar-item v-for="(n, i) in notifications" :key="`item-${i}`">
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu> -->

    <template v-if="userGetter.isAuth">
      <v-btn class="ml-2" min-width="0" text to="/pages/user">
        <v-icon>mdi-account</v-icon>
      </v-btn>

      <v-btn class="ml-2" min-width="0" text @click="signout">
        <v-icon>mdi-power</v-icon>
      </v-btn>
    </template>

    <template v-if="userGetter.isAuth"> </template>

    <template v-else>
      <v-btn class="ml-2" min-width="0" text @click="showAuthForm = true">
        <v-icon>mdi-login</v-icon>
      </v-btn>
    </template>
    <!-- <Auth
      v-if="showAuthForm"
      :dialog="showAuthForm"
      @closeDialog="showAuthForm = false"
    /> -->
    <Auth
      v-if="!userGetter.isAuth"
      :dialog="!userGetter.isAuth"
      @closeDialog="userGetter.isAuth"
    />
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib";
import Auth from "../../component/Auth";

// Utilities
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
    Auth,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    notifications: [
      "Mike John Responded to your email",
      "You have 5 new tasks",
      "You're now friends with Andrew",
      "Another Notification",
      "Another one",
    ],
    showAuthForm: false,
  }),

  computed: {
    ...mapState(["drawer", "userGetter"]),
    ...mapGetters(["userGetter"]),
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    ...mapActions(["signout"]),

    // changeLang(lang) {
    //   this.languageHandler(lang);
    // },

    // test() {
    //   console.log('test');
    //   this.testAction();
    // },

    changeLang(lang) {
      if (lang == "en") {
        this.$vuetify.rtl = false;
        this.$i18n.locale = "en";
      } else if (lang == "ar") {
        this.$vuetify.rtl = true;
        this.$i18n.locale = "ar";
      }
    },
  },
};
</script>
